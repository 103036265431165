<template>
  <div class="home">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div v-if="searchResult && showSearchResult" class="wrapper-content wrapper-content--search" ref="searchContent" @click="contentElementClicked($event)" :style="wrapperContentStyle" >
      <div class="content content--search" v-html="searchResult" ></div>
    </div>

    <div v-else class="wrapper-content wrapper-content--home" v-html="mainContent" ref="content" @click="contentElementClicked($event)"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Api } from "@/api";
import axios from "axios";
import RouterUtility from "@/utils/RouterUtility";
import PathUtility from "@/utils/PathUtility"; 
import ContentProvider from "@/utils/Provider"; 
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "home",
  components: {
    Loading
  },
  data() {
    return {
      mainContent: "",
      contentLoaded: "",
      hasPageOverflow: false,
      isLoading: false,
      fullPage: true,
      loader: "bars"
    };
  },
  computed: {
    ...mapGetters(["gSize", "gUser", "gSearchResult", "gShowSearchResult"]),
    wrapperContentStyle() {
      return "min-height: " + (this.gSize.screen.height - this.contentNavHeight - 176) + "px";
    },    
    searchResult() {
      return this.gSearchResult;
    },
    showSearchResult() {
      return this.gShowSearchResult;
    }    
  },
  props: {},
  created() {
    //console.log("[home created] route", this.$route);
    this.hasPageOverflow = this.$store.state.app.pageOverflow;
    this.setPageSettings();
    const uri = Api.BACKEND_URL + (this.$route.path!="/home" ? (this.$route.path.indexOf("/") == 0 ? this.$route.path.substr(1) : this.$route.path) : "")
    this.getContent(uri);
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    getContent(uri) {
      this.isLoading = true;
      //const uri = Api.BACKEND_URL + (this.$route.path!="/home" ? (this.$route.path.indexOf("/") == 0 ? this.$route.path.substr(1) : this.$route.path) : "");
      //console.log("getContent", uri);
      axios
        .create({ withCredentials: true })
        .get(uri)
        .then(response => {
          if (response.data) {
            this.mainContent = ContentProvider.extractMainContent(response.data);
            //this.extractMainContent(response.data);
            this.$nextTick(() => {
              ContentProvider.prepareContent(this.$refs.content);
            });
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => (setTimeout(this.isLoading = false, 500)));
    },
    contentElementClicked(event) {
      let link = PathUtility.checkLinkInPath(event);
      if (link) {
        if (RouterUtility.isPrevented(link)) {
          this.$router.push(RouterUtility.getPathFromLink(link));
          if (link.parentNode.classList.contains("tx-indexedsearch-title")) {
            this.$store.dispatch("showSearchResult", false);
          }
          event.preventDefault();          
        }
      }
    },
    setPageSettings() {
      if (this.hasPageOverflow) {
        document.body.classList.add("has--overflow");
        const root = document.getElementsByTagName("html")[0];
        root.classList.add("has--overflow");
      }
    },
    /* prepareContent() {
      if (this.$refs && this.$refs.content) {
        ContentProvider.prepateContent(this.$refs.content);
        let imgs = this.$refs.content.querySelectorAll("img");
        imgs.forEach(img => {
          img.src = Api.BACKEND_URL + img.dataset.src; //img.src.replace("http://192.168.0.240:8080", "https://aht.fixit.at");
          img.classList.remove("lazyload", "loading");
        });
        let videoLinks = this.$refs.content.querySelectorAll("a.link--video");

      }
    }, */
  }
};
</script>
